import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./footer.css"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAllgemein(id: { eq: "a184f87a-8247-5ceb-a1ea-8811849afd31" }) {
        footerText
        socialMediaButtons {
          link
          icon {
            localFile {
              publicURL
            }
            title
          }
        }
      }
    }
  `)
  return (
    <footer>
      <div className="social-buttons">
        {data.contentfulAllgemein.socialMediaButtons.map(button => (
          <a href={button.link} target="_blank" rel="noreferrer">
            <img
              src={button.icon.localFile.publicURL}
              alt={button.icon.title}
            ></img>
          </a>
        ))}
      </div>
      <div className="copyright-imprint">
        {data.contentfulAllgemein.footerText + ""}&nbsp;·&nbsp;{" "}
        <Link to="/imprint">Imprint</Link>
      </div>
    </footer>
  )
}

export default Footer
